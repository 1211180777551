"use client";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  ReactNode,
} from "react";
import { useCreateBooking } from "~/hooks/booking/useCreateBooking";
import { useMe } from "~/hooks/useMe";
import { Resource } from "~/types/Resource";
import { useBookingFlowRedux } from "./BookingFlowReducer";
import { useSessionStorage } from "react-use";
import { useGraphqlErrorToast } from "~/hooks/useGraphqlErrorToast";
import { ResourceBookingType } from "~/types/ResourceBookingType";
import { TheDateTime } from "~/_libs/time/TheDateTime";
import { TheDay } from "~/_libs/time/TheDay";
import { TheTime } from "~/_libs/time/TheTime";
import { Timespan } from "~/_libs/time/Timespan";

type BookingFlow = {
  isCreateBookingLoading: boolean;
  isBookingCreated: boolean;
  canBook: boolean;
  createBooking: () => void;
  setTheSelectedDate: (date: TheDay) => void;
  theSelectedDate: TheDay;
  setSelectedWorkspace: (workspaceId: string) => void;
  workspaceId?: string;
  startBookingFlow: (resource: Resource) => void;
  updateResource: (resource: Resource) => void;
  stopBookingFlow: () => void;
  resource?: Resource;
  setNewBookingTime: (start: TheTime, end: TheTime) => void;
  time?: Timespan;
  resetFlow: () => void;
  startFastBookingFlow: (
    selectedWorkspaceId: string,
    selectedResourceId: string,
    date?: TheDay,
    from?: TheTime,
    to?: TheTime,
  ) => void;
  recreateBookingFlow: (
    selectedWorkspaceId: string,
    selectedResourceId: string,
    date?: TheDay,
    from?: TheTime,
    to?: TheTime,
  ) => void;
};

const BookingFlowContext = createContext<BookingFlow>({
  isCreateBookingLoading: true,
  isBookingCreated: false,
  canBook: false,
  createBooking: () => null,
  setTheSelectedDate: () => null,
  theSelectedDate: TheDay.now(),
  setSelectedWorkspace: () => null,
  startBookingFlow: () => null,
  stopBookingFlow: () => null,
  resetFlow: () => null,
  startFastBookingFlow: () => null,
  recreateBookingFlow: () => null,
  setNewBookingTime: () => null,
  updateResource: () => null,
});
BookingFlowContext.displayName = "Booking flow context";

export const BookingFlowProvider = ({ children }: { children: ReactNode }) => {
  const [bookingDataStorage, setBookingDataStorage] = useSessionStorage<{
    workspaceId?: string;
    resource?: Resource;
    date?: string;
    time?: {
      fromTime?: string;
      toTime?: string;
    };
  }>("BOOKING_FLOW_BOOKING_DATA", {});

  const [bookingState, dispatch] = useBookingFlowRedux();
  useEffect(() => {
    dispatch({
      type: "bookingDataInitialized",
      bookingData: bookingDataStorage,
    });
  }, []);

  useEffect(() => {
    let date: string;
    if (bookingState?.booking?.date) {
      date = bookingState?.booking?.date?.toString();
    }

    let time = {};
    if (bookingState?.booking?.time) {
      time = {
        fromTime: bookingState?.booking?.time?.fromTime?.toString(),
        toTime: bookingState?.booking?.time?.toTime?.toString(),
      };
    }

    setBookingDataStorage({
      ...bookingState?.booking,
      date,
      time,
    });
  }, [bookingState?.booking, setBookingDataStorage]);

  const { paymentMethod, organization } = useMe();

  useEffect(() => {
    dispatch({
      type: "userDataChanged",
      userData: {
        paymentMethodId: paymentMethod?.id,
        organizationId: organization?.id,
      },
    });
  }, [dispatch, paymentMethod, organization]);

  const {
    data,
    error: createBookingError,
    reset,
    isLoading: isCreateBookingLoading,
    createNewBooking,
    createNewBookingRequest,
  } = useCreateBooking();

  const graphqlErrorToast = useGraphqlErrorToast();
  useEffect(() => {
    if (createBookingError) {
      reset?.();
      graphqlErrorToast(createBookingError);
    }
  }, [createBookingError, reset, graphqlErrorToast]);

  useEffect(() => {
    if (data?.id) {
      dispatch({ type: "bookingCreated" });
    }
  }, [data, dispatch]);

  const actions = useMemo(() => {
    return {
      setTheSelectedDate: (newDate: TheDay) =>
        dispatch({ type: "dateChanged", date: newDate }),
      setNewBookingTime: (start: TheTime, end: TheTime) =>
        dispatch({ type: "timeSelectionChanged", start, end }),
      startBookingFlow: (newResource: Resource) =>
        dispatch({
          type: "bookingFlowStarted",
          resource: newResource,
        }),
      updateResource: (updatedResourceData: Resource) =>
        dispatch({
          type: "resourceUpdated",
          resource: updatedResourceData,
        }),
      setSelectedWorkspace: (newWorkspaceId: string) =>
        dispatch({
          type: "workspaceChanged",
          workspaceId: newWorkspaceId,
        }),
      stopBookingFlow: () => dispatch({ type: "bookingFlowStopped" }),
      resetFlow: () => dispatch({ type: "flowCleared" }),
      startFastBookingFlow: (
        selectedWorkspaceId: string,
        selectedResourceId: string,
        selectedDate?: TheDay,
        selectedFrom?: TheTime,
        selectedTo?: TheTime,
      ) =>
        dispatch({
          type: "fastBookingFlowStarted",
          workspaceId: selectedWorkspaceId,
          resourceId: selectedResourceId,
          date: selectedDate,
          from: selectedFrom,
          to: selectedTo,
        }),
      recreateBookingFlow: (
        selectedWorkspaceId: string,
        selectedResourceId: string,
        selectedDate?: TheDay,
        selectedFrom?: TheTime,
        selectedTo?: TheTime,
      ) =>
        dispatch({
          type: "recreateBookingFlowStarted",
          workspaceId: selectedWorkspaceId,
          resourceId: selectedResourceId,
          date: selectedDate,
          from: selectedFrom,
          to: selectedTo,
        }),
    };
  }, [dispatch]);

  const createBooking = useCallback(() => {
    const { user, booking } = bookingState;

    const interval = TheTime.intervalToDuration({
      start: booking?.time?.fromTime,
      end: booking?.time?.toTime,
    });
    const durationInMinutes = interval.minutes + interval.hours * 60;

    const newBooking = {
      durationInMinutes,
      resourceId: booking?.resource?.id,
      startDateTime: TheDateTime.fromDateAndTimeString(
        booking?.date?.toString(),
        booking?.time?.fromTime?.toString(),
      ).toString(),
      ...user,
    };

    const { bookingType } = booking?.resource ?? {};

    switch (bookingType) {
      case ResourceBookingType.INSTANT:
        createNewBooking(newBooking);
        return;
      case ResourceBookingType.BY_REQUEST:
        createNewBookingRequest(newBooking);
        return;
      default:
        throw Error(`Booking type of ${bookingType} not supported`);
    }
  }, [createNewBooking, createNewBookingRequest, bookingState]);

  return (
    <BookingFlowContext.Provider
      value={{
        canBook: bookingState?.canBook,
        isBookingCreated: bookingState?.isCreated,
        createBooking,
        isCreateBookingLoading,
        ...actions,
        workspaceId: bookingState?.booking?.workspaceId,
        theSelectedDate: bookingState?.booking?.date,
        resource: bookingState?.booking?.resource,
        time: bookingState?.booking?.time,
      }}
    >
      {children}
    </BookingFlowContext.Provider>
  );
};

export const useBookingFlow = () => {
  return useContext(BookingFlowContext);
};
