import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useMemo } from "react";

export const useNavigator = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { push } = useRouter();

  return useMemo(() => {
    const url = `${pathname}${
      searchParams.toString() ? `?${searchParams.toString()}` : ""
    }`;

    return {
      url,
      navigateTo: (url: string) => {
        push(url);
      },
      goToSaved: () => push("/saved"),
      goToCommunity: (tab: CommunityTabs) => {
        push(`/community?tab=${tab}`);
      },
      goToHome: () => push("/home"),
      goToBook: () => push("/book"),
      goToAddCard: () => push("/account/payments/add-card"),
      goToSearchResults: () => push(`/search?${searchParams.toString()}`),
      goToWorkspaceDetails: (workspaceId: string) => {
        push(`/workspace/${workspaceId}`);
      },
      replace: (url: string) => push(url),
      queryData: {
        newWindow: searchParams.get("newWindow"),
        returnTo: searchParams.get("returnTo"),
        profileId: searchParams.get("profileId"),
        workspaceId: searchParams.get("workspaceId"),
        resourceId: searchParams.get("resourceId"),
        workspaceName: searchParams.get("workspaceName"),
        resourceName: searchParams.get("resourceName"),
        resourcePreviewId: searchParams.get("resourcePreviewId"),
        startTime: searchParams.get("startTime"),
        endTime: searchParams.get("endTime"),
        time: searchParams.get("time"),
        date: searchParams.get("date"),
        tab: searchParams.get("tab") as
          | CoworkerTabs
          | TeamDetailsTabs
          | TeamTabs
          | CommunityTabs,
        teamId: searchParams.get("teamId"),
      },
      pathname,
    };
  }, [pathname, searchParams?.toString(), push]);
};

export enum TeamTabs {
  YOUR_TEAMS = "YOUR_TEAMS",
  ALL_TEAMS = "ALL_TEAMS",
}

export enum TeamDetailsTabs {
  TEAM_DETAILS = "TEAM_DETAILS",
  MANAGE_MEMBERS = "MANAGE_MEMBERS",
}

export enum CoworkerTabs {
  FOLLOWING = "FOLLOWING",
  ALL = "ALL",
  FOLLOWERS = "FOLLOWERS",
}

export enum CommunityTabs {
  TEAMS = "TEAMS",
  PEOPLE = "PEOPLE",
}
